import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Divider, InputBase, Typography } from '@mui/material';
import messages from 'src/components/LandingMozza/common/Hero/SearchBar/messages';
import useStyles from 'src/components/LandingMozza/common/Hero/SearchBar/styles';

import FamousTags from '@/components/LandingMozza/common/Hero/SearchBar/FamousTags/FamousTags';
import RatingGoogle from '@/components/LandingMozza/common/RatingGoogle/RatingGoogle';
import Button from '@/designSystem/Button/Button';
import { useQueryImmutable } from '@/hooks/useQueryImmutable';
import Options from '@/Organisms/SearchBar/DrawerOptions/DrawerOptions';
import { orderSubjects } from '@/Organisms/SearchBar/utils';
import { apiGetSubjects } from '@/shared/API/apiGetSubjects';
import { defaultSearchSubjects } from '@/shared/defaultSearchSubjects';
import useNavigation from '@/shared/Hooks/useNavigation';
import handleSendEventGTM from '@/utils/googleTagManagerHelper';

const SearchBar: FC = () => {
  const inputRef = useRef<HTMLDivElement>();

  const { classes } = useStyles();
  const { pushTo, urls } = useNavigation();
  const { isLoading, data: subjects } = useQueryImmutable(
    ['subject'],
    apiGetSubjects
  );
  const [subject, setSubject] = useState('');
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [options, setOptions] = useState(defaultSearchSubjects);
  const { formatMessage } = useIntl();

  // search
  const onSearchClick = useCallback(() => {
    const params: { subject?: string } = {};
    if (subject) {
      params.subject = subject;
    }

    pushTo(
      urls.search(params.subject ? { subject: params.subject } : undefined)
    );

    handleSendEventGTM('searchTeacher_heroLanding', { params });
  }, [pushTo, subject, urls]);

  // autocomplete
  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef?.current && !inputRef?.current?.contains(event.target)) {
        setIsOptionOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef]);

  const getOptions = (inputValue) => {
    setIsOptionOpen(true);
    const dataOptions = orderSubjects(isLoading ? [] : subjects, inputValue);
    if (dataOptions?.length > 0) {
      setOptions(dataOptions);
    } else if (inputValue.length === 0) {
      setOptions(defaultSearchSubjects);
    } else {
      setOptions([]);
    }
  };

  const updateSubjectInput = (inputSubject: string) => {
    getOptions(inputSubject);
    setSubject(inputSubject);
  };
  const onOptionClick = (option: string) => {
    setSubject(option);
    setIsOptionOpen(false);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      const input = e.target.value;
      setSubject(input);
      setIsOptionOpen(false);
    }
  };

  useEffect(() => {
    if (subjects?.map((s) => s.name).includes(subject)) {
      onSearchClick();
    }
  }, [onSearchClick, subject, subjects]);

  return (
    <div className={classes.container}>
      <div className={classes.searchAndTags}>
        <div className={classes.search} ref={inputRef}>
          <div className={classes.searchInput}>
            <Typography variant="bodyMdBold">
              <FormattedMessage {...messages.subject} />
            </Typography>
            <InputBase
              className={classes.input}
              placeholder={formatMessage(messages.placeholder)}
              fullWidth
              onKeyDown={onKeyDown}
              onChange={(e) => updateSubjectInput(e.target.value)}
              onFocus={(e) => updateSubjectInput(e.target.value)}
            />
            {isOptionOpen && (
              <div className={classes.options}>
                <Options options={options} onClick={onOptionClick} />
              </div>
            )}
          </div>
          <div className={classes.searchBtnContainer}>
            <Button
              variant="primary"
              size="medium"
              isFullWidth
              onClick={onSearchClick}
              label={formatMessage(messages.find)}
            />
          </div>
        </div>
        <Divider orientation="horizontal" flexItem />

        <FamousTags />
      </div>
      <RatingGoogle
        className={classes.ratingGoogle}
        gtmEvent="'gmb_heroLanding'"
      />
    </div>
  );
};

export default SearchBar;
