import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: rem(24), // for Navbar
    [breakpoints.up(1200)]: {
      justifyContent: 'space-between',
      gap: rem(44),
      paddingTop: rem(32), // for Navbar
    },
  },
  header: {
    marginBottom: rem(32),
    display: 'flex',
    flexDirection: 'column',
    gap: rem(16),
  },
  reasons: {
    display: 'none',
    [breakpoints.up('md')]: {
      listStyle: 'none',
      margin: `0 0 ${rem(40)}`,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: rem(16),
    },
  },
  neutralText: {
    color: palette.neutral[200],
  },
  displayInline: {
    display: 'inline',
  },
  imageContainer: {
    display: 'none',
    [breakpoints.up(1200)]: {
      display: 'block',
    },
  },
}));
