import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.LandingMozza.Hero.title',
    defaultMessage:
      'Bienvenue dans le nouveau soutien scolaire, sur mesure et équitable',
  },
  subtitlePart1: {
    id: 'app.components.LandingMozza.Hero.subtitlePart1',
    defaultMessage: 'Notre mission : ',
  },
  subtitlePart2: {
    id: 'app.components.LandingMozza.Hero.subtitlePart2',
    defaultMessage: 'des cours particuliers de qualité, au meilleur prix.',
  },
  imageAlt: {
    id: 'app.components.LandingMozza.Hero.altImage',
    defaultMessage: 'Profs de maths et d’anglais certifiés sur Sherpas.com',
  },
});
