import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'landingMozza.seoLinks.title',
    defaultMessage:
      '<break>Des cours particuliers adaptés à tous les niveaux :</break>Collège, Lycée, Prépa, Supérieur',
  },
  description: {
    id: 'landingMozza.seoLinks.description',
    defaultMessage: `<break>Vous trouverez forcément le Sherpa qu'il vous faut parmi nos </break>3000 professeurs particuliers basés dans toute la France.`,
  },
});
