import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import messages from 'src/components/LandingMozza/common/Hero/SearchBar/FamousTags/messages';
import useStyles from 'src/components/LandingMozza/common/Hero/SearchBar/FamousTags/styles';

import Badge from '@/Molecules/Badge/Badge';
import useNavigation from '@/shared/Hooks/useNavigation';
import handleSendEventGTM from '@/utils/googleTagManagerHelper';

const FamousTags: FC = () => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const { pushTo, urls } = useNavigation();

  const handleClick = (subject: string) => {
    if (subject === 'plus20') {
      pushTo(urls.search());
      return;
    }
    pushTo(urls.search({ subject: formatMessage(messages[subject]) }));
    handleSendEventGTM('search_from_famous_tags', { subject });
  };

  return (
    <div className={classes.container}>
      <Typography variant="bodyMdBold" className={classes.hideLabelIfMobile}>
        <FormattedMessage {...messages.populaires} />
      </Typography>
      {[
        'maths',
        'physique',
        'anglais',
        'espagnol',
        'francais',
        'histoire',
        'plus20',
      ].map((key) => (
        <Badge
          key={key}
          color="white"
          className={classes.badge}
          onClick={() => handleClick(key)}
          variant="bodyMd"
        >
          <FormattedMessage {...messages[key]} />
        </Badge>
      ))}
    </div>
  );
};

export default FamousTags;
